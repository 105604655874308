<template>
  <v-app id="app">
    <v-main>
      <v-container fluid class="pa-0">
        <component :is="layout">
          <router-view />
        </component>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
const LayoutDefault = () => import('@/layout/Default.vue')
const LayoutFull = () => import('@/layout/Full.vue')
export default {
  name: 'App',
  components: {
    LayoutDefault,
    LayoutFull
  },
  computed: {
    layout() {
      if (!this.$route.meta.layout) return 'layout-default'
      return `layout-${this.$route.meta.layout}`
    },
  }
};
</script>

<style lang="scss">
html, #app {
  width: 100vw;
  height: 100%;
}
.fill-height {
  height: 100%;
}
.grecaptcha-badge { 
  visibility: hidden; 
}
</style>